<template>
  <div class="homepage">
    <HeroImgSlider :list="homepageData.heroImages" />
    <div class="homepage-content">
      <Destinations class="section-sm" />
      <Benefits class="section-lg" />
      <HomeFeaturedTours class="section" />
      <AboutUs class="section" />
      <OurBlog class="section" />
      <PressSection
        class="section"
        :screen-width="screenWidth"
        :title="homepageData.recommendedByTitle"
        :presses="pressesData.presses"
      />
    </div>
  </div>
</template>

<script>
import pressesData from '@/data/presses.json'
import HeroImgSlider from '@/components/containers/home/HeroImgSlider'
import Destinations from '@/components/containers/home/Destinations'
import Benefits from '@/components/containers/home/Benefits'
import HomeFeaturedTours from '@/components/containers/home/HomeFeaturedTours'
import AboutUs from '@/components/containers/home/AboutUs'
import OurBlog from '@/components/containers/home/OurBlog'
import PressSection from '@/components/utils/PressSection'

import { mapGetters } from 'vuex'
import setRobots from '~/mixins/setRobots.js'
import imageOptimo from '~/mixins/imageOptimoMixin.js'

export default {
  watchQuery: true,
  name: 'homepage',
  mixins: [ setRobots,imageOptimo ],
  components: {
    HeroImgSlider,
    Destinations,
    Benefits,
    HomeFeaturedTours,
    AboutUs,
    OurBlog,
    PressSection
  },
  computed: {
    ...mapGetters([
      'homepageMetaTitle',
      'homepageMetaDescription',
      'organizationMicroData',
      'websiteMicroData',
      'heroImage',
      'homepageData',
      'screenWidth'
    ]),
    firstImg () {
      const heroImgs = this.homepageData.heroImages;
      if (heroImgs?.length > 0) {
        return heroImgs[0].imgUrl
      }
      return ''
    }
  },
  data () {
    return {
      pressesData: pressesData,
    }
  },
  mounted() {
    this.gtmPageView({
        'hostname': 'www.walksofitaly.com',
        'fullURL': 'www.walksofitaly.com',
        'pagePath': '/',
        'pageTitle': this.homepageMetaTitle !== undefined ? this.homepageMetaTitle : '',
        'referrer': document.referrer
      })
    window.dataLayer.push({
      'event': 'domReady',
      'domReady': {
        'hostname': 'www.walksofitaly.com',
        'fullURL': 'www.walksofitaly.com',
        'pagePath': '/',
        'pageTitle': this.homepageMetaTitle !== undefined ? this.homepageMetaTitle : ''
      }
    })
    this.$store.commit('initRecentlyViewed')
    // client-side update review
    this.$store.dispatch('getHomepageReviewStat')
  },
  methods: {
    /**
    getMetaURL returns the URL for the current page
     */
    getMetaURL () {
      return 'https://www.walksofitaly.com/'
    },
    getLinks () {
      const baseLinks = [{ 'rel': 'canonical', 'href': this.getMetaURL() }];

      // return baseLinks;
      if (!this.firstImg) {
        return baseLinks;
      }

      const imgUrl = this.getContentfulImgURL({ url: this.firstImg, width: 1200 });

      return [
        ...baseLinks,
        {
          rel: 'preload',
          as: 'image',
          href: imgUrl,
        }
      ];
    }
  },
  head () {
    return {
      title: this.homepageMetaTitle !== undefined ? this.homepageMetaTitle : '',
      meta: [
        {
          hid: 'robots', 
          name: 'robots', 
          content: this.setRobotsTag()
        },
        {
          hid: 'description',
          name: 'description',
          content: this.homepageMetaDescription !== undefined ? this.homepageMetaDescription : ''
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this.homepageMetaTitle !== undefined ? this.homepageMetaTitle : ''
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this.homepageMetaDescription !== undefined ? this.homepageMetaDescription : ''
        },
        {
          hid: 'og:type',
          name: 'og:type',
          property: 'og:type',
          content : 'article'
        },
        {
          hid: 'og:url',
          name: 'og:url',
          property: 'og:url',
          content: this.getMetaURL()
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: this.heroImage
        },
        {
          hid: 'fb:app_id',
          name: 'fb:app_id',
          content: 1989124628036695
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          property: 'twitter:site',
          content: '@walks'
        },
        {
          property: 'twitter:title',
          content: this.homepageMetaTitle !== undefined ? this.homepageMetaTitle : ''
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          property: 'twitter:description',
          content: this.homepageMetaDescription !== undefined ? this.homepageMetaDescription : ''
        },
        {
          property: 'twitter:image',
          content: 'https:' + this.heroImage
        }
      ],
      script: [
        { 
          hid: 'ldjson-schema',
          innerHTML: this.organizationMicroData,
          type: 'application/ld+json'
        },
        { 
          hid: 'ldjson-schema',
          innerHTML: this.websiteMicroData,
          type: 'application/ld+json'
        }
      ],
      link: this.getLinks(),
      __dangerouslyDisableSanitizersByTagID: {
        'ldjson-schema': ['innerHTML'], 
      }
    }
  },
  async fetch ({store}) {
    await store.dispatch('getNewHomepage')
  }
}
</script>

<style lang="scss" scoped>
.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  .homepage-content {
    width: 1160px;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .section-sm {
    margin-top: 1rem;
  }
  .section-lg {
    margin: 0;
    margin-top: 5rem;
  }
  .section {
    margin: 0;
    margin-top: 4rem;
  }
}

@include max-width($homepage-limit-width) {
  .homepage .homepage-content {
    width: Min(1100px, calc(100vw - 48px));
  }
}
// 1024
@include max-width ($tablet) {
  .homepage .homepage-content {
    width: calc(100vw - 3rem);
  }
  .homepage {
    .section-lg {
      margin-top: 4rem;
    }
    .section {
      margin-top: 3rem;
    }
  }
}
// 768
@include max-width ($tablet-portrait) {
  .homepage .homepage-content {
    width: calc(100vw - 2rem);
  }
  .homepage {
    .section-lg {
      margin-top: 3rem;
    }
    .section {
      margin-top: 2rem;
    }
  }
}

// 700
@include max-width($mobile-extra) {
  .homepage .homepage-content {
    width: calc(100vw - 1rem);
  }
}
@include max-width($mobile-l) {
  .homepage .homepage-content {
    width: $mobile-width;
  }
}
</style>

