<template>
  <span v-if="stars" id="stars-container">
    <div v-for="idx in getFullHeartNum()" :key="idx">
      <svgicon class="star" name="full-star" :width="size" :height="size" />
    </div>
    <div v-if="hasHalfHeart()">
      <svgicon
        class="star"
        name="half-star"
        :width="size"
        :height="size"
      />
    </div>
    <div v-for="idx in getEmptyHeartNum()" :key="5 + idx">
      <svgicon
        class="star empty-star"
        name="empty-star"
        :width="size"
        :height="size"
      />
    </div>
  </span>
  <span v-else id="stars-container">
    <div v-for="idx in 5" :key="5 + idx">
      <svgicon
        class="star"
        name="full-star"
        :width="size"
        :height="size"
      />
    </div>
  </span>
</template>

<script>
import { calAverage } from '@/helpers/rating'
export default {
  name: 'Stars',
  props: {
    stars: {
      type: Number,
      require: true
    },
    size: {
      type: String,
      require: true,
      default: () => '24'
    }
  },
  methods: {
    getFullHeartNum () {
      return Math.floor(calAverage(this.stars))
    },
    hasHalfHeart () {
      // console.log('star ==> ', this.stars)
      return calAverage(this.stars) - this.getFullHeartNum() !== 0
    },
    getEmptyHeartNum () {
      if (this.hasHalfHeart()) {
        return 5 - this.getFullHeartNum() - 1
      }
      return 5 - this.getFullHeartNum()
    }
  }
}
</script>

<style lang="scss" scoped>
#stars-container {
  display: flex;
  align-items: center;
  height: min-content;
  > div {
    // display: inline-block;
    margin-right: 2px;
  }
  .star {
    color: $woi-gold-main;
  }
}

</style>
