<template>
  <div class="homepage-featured-tours top-border">
    <div class="on-border top-icon">
      <div class="icon-wrapper">
        <svgicon name="subtract" width="24" height="24" />
      </div>
      </div>
    <div class="homepage-tabs" :class="{noRecentViewed: hasNoRecentReviews()}">
      <div v-for="(tab, idx) in tabs"
        :href="`#${tab.key}`"
        :key="idx" :class="{active: isActive(idx), middle: !hasNoRecentReviews() && idx === 1, disabled: isDisable(idx)}" 
        @click="useTab(idx)"
      >
        <span>{{ tab.title }}</span>
      </div>
    </div>
    <transition :id="tab.key" v-for="(tab, idx) in tabs" :key="idx" name="fade">
      <div class="tab-content" v-if="isActive(idx)">
        <HomeSlider
          :id="`${tab.key}-slider`"
          class="featured-tours-slider"
          :list="getContentLst(idx)"
          :card-width="cardWidth"
        >
          <template #content="{ item }">
            <div class="card-wrapper">
              <TourCardVertical :data="item" />
            </div>
          </template>
        </HomeSlider>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TourCardVertical from '@/components/utils/TourCardVertical'
import HomeSlider from '@/components/containers/home/HomeSlider'

export default {
  name: 'HomeFeaturedTours',
  components: {
    HomeSlider,
    TourCardVertical
  },
  computed: {
    ...mapGetters([
      'screenWidth',
      'homepageFeaturedTours',
      'recentlyViewed'
    ]),
    featuredTours () {
      return this.homepageFeaturedTours
    },
    cardWidth () {
      if (this.screenWidth > 768) {
        return 334
      }
      return 264
    },
    tabs () {
      return [
        { title: this.homepageFeaturedTours?.recentlyViewedTitle || '', key: 'recentlyViewed'}, 
        { title: this.homepageFeaturedTours?.popularToursTitle || '', key: 'popularTours'}, 
        { title: this.homepageFeaturedTours?.newToursTitle || '', key: 'newTours'}
      ];
    }
  },
  data () {
    return {
      activeTabIdx: 1,
    }
  },
  methods: {
    isActive (idx) {
      return this.activeTabIdx === idx
    },
    hasNoRecentReviews () {
      const res =  this.isDisable(0)
      // console.log('noRecentReviews res => ', res)
      return res
    },
    isDisable (idx) {
      const lst = this.getContentLst(idx)
      // console.log('lst => ', lst)
      return !lst || lst.length === 0
      // return false
    },
    useTab (idx) {
      if (this.isDisable(idx)) {
        return
      }
      this.activeTabIdx = idx
    },
    getContentLst (idx) {
      const key = this.tabs[idx]?.key
      if (key === 'recentlyViewed') {
        return this.recentlyViewed
      }
      return this.homepageFeaturedTours?.[key]
    }
  }
}
</script>

<style lang="scss" scoped>
.homepage-featured-tours {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.top-border {
    position: relative;
    padding-top: 30px;
    border-top: 2px solid $woi-red-main;
    // display: flex;
    // justify-content: space-between;
    .on-border {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
      background-color: transparent;
      &.top-icon {
        display: flex;
        justify-content: center;
        width: 0;
        top: -12px;
        .icon-wrapper {
          position: absolute;
          background-color: $white;
          height: fit-content;
          width: fit-content;
          color: $woi-red-main;
          padding-left: 1rem;
          padding-right: 1rem;
          // top: -12px;
        }
      }
    }
  }
  .homepage-tabs {
    margin: 0;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid $blackberry;
    display: grid;
    grid-template-columns: repeat(3, 300px);
    justify-content: space-between;
    align-items: center;
    &.noRecentViewed {
      grid-template-columns: repeat(2, 1fr);
    }
    .middle {
      justify-self: center;
    }
    .disabled {
      display: none;
      // color: gray;
    }
    div {
      cursor: pointer;
      transition: border-bottom 0.5s;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      > span {
        @include woi-header-4;
        font-weight: 400 !important;
        padding: 10px 20px;
        color: $woi-red-main;
      }
      
      &.active {
        border-bottom: 3px solid $blackberry;
        > span {
          font-weight: 700 !important;
        }
      }
    }
  }
  .fade-enter-active {
    transition: opacity .5s;  
  }
  .fade-enter {
    // display: block;
    opacity: 0.3;
    // transform: translate3d(-100px, 0, 0);
  }
  .fade-leave-active {
    transition: none;
  }
  .fade-leave-to {
    // display: none;
    opacity: 0;
  }

  .tab-content {
    width: 100%;
    margin-top: 0;
  }

  .card-wrapper {
    width: 334px;
  }
}
// 1024
@include max-width($tablet) {
  .homepage-featured-tours {
    .homepage-tabs {
      grid-template-columns: repeat(3, 1fr);
      div > span {
        @include woi-header-5;
        font-weight: 400 !important;
      }
    }
  }
}
// 768
@include max-width($tablet-portrait) {
  .homepage-featured-tours {
    .homepage-tabs {
      div > span {
        font-size: 24px !important;
        line-height: 32px;
        padding: 0.5rem;
      }
    }
    .card-wrapper {
      width: 264px;
    }
  }
}

@include max-width($mobile-l) {
  .homepage-featured-tours {
    &.top-border {
      padding-top: 0.5rem;
      .on-border.top-icon {
        bottom: -12px;
      }
    }
    
    .homepage-tabs {
      grid-template-columns: repeat(3, 108px);
      height: 64px;
      &.noRecentViewed {
        height: 42px;
      }
      div {
        height: 100%;
        & > span {
          font-size: 14px !important;
          line-height: 22px;
          padding: 0.5rem;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }
  }
}
</style>