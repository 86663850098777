<template>
  <div class="benefits-bordered">
    <div class="top-border">
      <div class="selling-point" v-for="(sellingPoint, index) in this.sellingPoints" :key="index">
        <div class="point-icon">
          <svgicon :name="pointIcons[index]" />
        </div>
        <div class="title">
          {{ sellingPoint.title }}
        </div>
        <div class="description">
          {{ sellingPoint.description }}
        </div>
      </div>
      <div class="on-border top-text">{{ sellingPointsTitle }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Benefits',
  computed: {
    ...mapGetters([
      'sellingPointsTitle',
      'sellingPoints'
    ])
  },
  data () {
    return {
      pointIcons: ['point-icon1', 'point-icon2', 'point-icon3']
    }
  }
}
</script>
<style lang="scss" scoped>
.benefits-bordered {
  // background-color: $woi-red-main;
  width: 100%;
  min-height: 200px;
  .top-border {
    position: relative;
    padding: 30px 48px;
    padding-bottom: 0;
    border-top: 2px solid $woi-red-main;
    display: flex;
    justify-content: space-between;
    .on-border {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
      background-color: $white;
      &.top-text {
        width: fit-content;
        padding-left: 1rem;
        padding-right: 1rem;
        text-align: center;
        @include woi-header-4;
        color: $woi-red-main;
        top: -21px;
      }
    }
    .selling-point {
      display: flex;
      flex-direction: column;
      align-items: center;
      // margin: 30px;
      .point-icon {
        color: $woi-red-main;
        border: 1px solid #767769;
        border-radius: 50%;
        > svg {
          width: 48px;
          height: 48px;
        }
      }
      .title {
        margin: 12px 0;
        @include woi-header-5;
        text-align: center;
        color: $woi-red-main;
      }
      .description {
        max-width: 280px;
        @include subtitle-2;
        font-family: "Open Sans";
        color: #6C757D;
        text-align: center;
      }
    }
  }
}
@include max-width($tablet) {
  .benefits-bordered .top-border {
    padding: 30px 16px;
  }
}
@include max-width($tablet-portrait) {
  .benefits-bordered .top-border {
    .on-border {
      &.top-text {
        // width: 220px;
        // text-align: center;
        @include woi-header-5;
        top: -16px;
      }
    }
    .selling-point {
      
      .point-icon {
        // margin-right: 0.5rem;
        > svg {
          width: 30px;
          height: 30px;
        }
      }
      .title {
        margin: 0;
        font-size: 18px !important;
        font-weight: 700 !important;
        line-height: 22px;
      }
      .description {
        // margin-top: 0.5rem;
        @include paragraph-1;
        font-family: "Open Sans";
      }
    }
  }
}
@include max-width($mobile) { 
  .benefits-bordered .top-border {
    padding: 30px 16px;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    .on-border {
      &.top-text {
        // width: 220px;
        // text-align: center;
        @include woi-header-5;
        top: -16px;
      }
    }
    .selling-point {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 450px;
      .point-icon {
        margin-right: 12px;
      }
      .title {
        margin: 0;
        min-width: 150px;
        text-align: left;
      }
      .description {
        margin-top: 0.5rem;
        min-width: 280px;
      }
    }
  }
}
</style>
