<template>
  <div class="homepage-hero-img-container">
    <VueSlickCarousel class="slider-wrapper" ref="homepageHeroImgs" v-bind="defaultSettings">
      <div class="hero-img-wrapper" v-for="(content, idx) in this.list" :key="idx">
        <img
          v-if="idx === 0"
          class="hero-img"
          draggable="false"
          :src="getImageSrc(content.imgUrl)"
          :srcset="getImageSrcSet(content.imgUrl, [1200, 2000])"
        >
        <img
          v-else
          class="hero-img lazyload"
          draggable="false"
          :data-src="getImageSrc(content.imgUrl)"
          :data-srcset="getImageSrcSet(content.imgUrl)"
        >
      </div>
    </VueSlickCarousel>
    <div class="hero-text-bg-box"></div>
    <div class="hero-text-box">
      <h1>{{this.heroTitle}}</h1>
      <div class="sub-title">{{this.heroDescription}}</div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import imageOptimo from '~/mixins/imageOptimoMixin.js'

export default {
  name: 'HeroImgSlider',
  mixins: [imageOptimo],
  components: {
    VueSlickCarousel
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters([
      'screenWidth',
      'homepageData'
    ]),
    heroTitle () {
      return this.homepageData.heroTitle
    },
    heroDescription () {
      return this.homepageData.heroDescription
    }
  },
  data () {
    return {
      curIndex: 0,
      screenChanged: false,
      defaultSettings: {
        autoplay:false,
        autoplaySpeed: 7000,
        infinite: false,
        lazyloaded: true,
        dots: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: 'linear',
        speed: 500
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.defaultSettings.infinite = true
        this.defaultSettings.autoplay = true
      }, 7000)
    })
  },
  methods: {
    getImageSrc (imgUrl) {
      return this.getContentfulImgURL({ url: imgUrl, width: 400 })
    },
    getImageSrcSet (imgUrl, sizes) {
      return this.getImageSrcSetURL({ url: imgUrl, sizes });
    },
  }
}
</script>

<style lang="scss" scoped>
$heroImgHeight: 546px;
.homepage-hero-img-container {
  position: relative;
  width: 100vw;
  height: $heroImgHeight;
  overflow: hidden;
  .slider-wrapper {
    width: 100%;
    height: $heroImgHeight;
  }
  .hero-img-wrapper {
    background-color: #5E2013;
    height: $heroImgHeight;
    overflow: hidden;
    &:hover {
      cursor: pointer;
    }
    img.hero-img {
      z-index: -1;
      width: 100%;
      height: $heroImgHeight;
      object-fit: cover;
      object-position: center;
    }
  }
  .hero-text-bg-box,
  .hero-text-box {
    position: absolute;
    bottom: 68px;
    left: 0;
    right: 0;
    margin: auto;
    width: 1145px;
    height: 194px;
  }
  .hero-text-bg-box {
    flex-shrink: 0;
    border: 2px solid $white;
    opacity: 0.4;
    background: linear-gradient(180deg, rgba(94, 32, 19, 0.00) 0%, #5E2013 72.78%);
  }
  .hero-text-box {
    padding: 38px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    margin-top: 38px;
    color: $white;
    text-align: center;
    @include header-2;
  }
  .sub-title {
    // margin-top: 18px;
    width: 826px;
    color: $white;
    text-align: center;
    @include woi-header-5;
    margin-top: 0.5rem;
  }
}
// $heroImgHeightVar: 
@include max-width($limit-width) {
  .homepage-hero-img-container {
    .hero-text-bg-box,
    .hero-text-box {
      width: 80%;
    }
    .hero-text-box {
      padding: 1rem 0.5rem;
      justify-content: space-evenly;
    }
    h1 {
      margin-top: 38px;
      @include header-3;
    }
    .sub-title {
      width: 100%;
      @include subtitle-2;
      font-weight: 500;
    }
  }
}

$heroImgHeightMobile: 391px;
// 700
@include max-width($mobile-extra) {
  .homepage-hero-img-container {
    height: $heroImgHeightMobile;
    .slider-wrapper {
      height: $heroImgHeightMobile;
    }
    .hero-img-wrapper {
      height: $heroImgHeightMobile;
      img.hero-img {
        height: $heroImgHeightMobile;
        opacity: 0.8;
      }
    }
    .hero-text-bg-box,
    .hero-text-box {
      top: 0;
      bottom: 0;
      width: $mobile-width;
      height: 318px;
    }
    .hero-text-box {
      padding: 1rem 0.5rem;
      justify-content: space-evenly;
    }
    h1 {
      margin-top: 38px;
      @include header-3;
    }
    .sub-title {
      width: 100%;
      @include subtitle-2;
      font-size: 18px !important;
      font-weight: 400 !important;
      font-weight: 500;
      margin-top: 0;
    }
  }
}
</style>

