<template>
  <div class="homepage-blog top-border">
    <div class="on-border top-text">{{ this.title }}</div>
    <div class="blog-slider-wrapper">
      <HomeSlider
        :id="'home-blog-slider'"
        class="home-blog-slider"
        :list="this.blogs"
        :card-width="cardWidth"
      >
        <template #content="{ item }">
          <div class="card-wrapper">
            <HomeBlogCard :info="item" />
          </div>
        </template>
        <a class="primary-btn link blog-btn" target="_blank" href="https://walksofitaly.com/blog/" slot="button">
          VIEW OUR BLOG
        </a>
      </HomeSlider>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import imageOptimo from '~/mixins/imageOptimoMixin.js'
import HomeSlider from '@/components/containers/home/HomeSlider'
import HomeBlogCard from '@/components/containers/home/HomeBlogCard'

export default {
  name: 'OurBlog',
  mixins: [imageOptimo],
  components: {
    HomeSlider,
    HomeBlogCard
  },
  computed: {
    ...mapGetters([
      'screenWidth',
      'blogSection'
    ]),
    title () {
      return this.blogSection?.title
    },
    blogs () {
      return this.blogSection?.blogs
    },
    cardWidth () {
      if (this.screenWidth > 768) {
        return 334
      }
      return 264
    }
  }
}
</script>

<style lang="scss" scoped>
.homepage-blog {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.top-border {
    position: relative;
    padding: 2rem 0;
    border-top: 2px solid $woi-red-main;
    display: flex;
    justify-content: space-between;
    .on-border {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
      background-color: $white;
      &.top-text {
        width: fit-content;
        padding-left: 1rem;
        padding-right: 1rem;
        text-align: center;
        @include woi-header-4;
        color: $woi-red-main;
        top: -21px;
      }
    }
  }
  a.link {
    cursor: pointer;
  }
  .primary-btn {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    // text-decoration: underline !important;
    text-transform: uppercase;
    color: $woi-gold-main;
    border: 1px solid $woi-gold-main;
    padding: 12px 16px;
  }
  .blog-btn {
    margin-top: 2rem;
    text-align: center;
  }
  .card-wrapper {
    width: 334px;
  }
}
// 768
@include max-width($tablet-portrait) {
  .homepage-blog {
    &.top-border {
      padding: 30px 0;
      align-items: center;
      gap: 1rem;
      .on-border {
        &.top-text {
          // width: 160px;
          @include woi-header-5;
          top: -16px;
        }
      }
    }
    .blog-slider-wrapper {
      width: 100%;
    }
    .blog-btn {
      margin-top: 1rem;
      width: 100%;
    }
    .card-wrapper {
      width: 264px;
    }
  }
}
@include max-width($mobile-l) {
  .homepage-blog {
    &.top-border {
      padding: 30px 0;
      align-items: center;
      gap: 1rem;
      .on-border {
        &.top-text {
          // width: 160px;
          @include woi-header-5;
          top: -16px;
        }
      }
    }
    .card-wrapper {
      width: 264px;
    }
  }
}
</style>