<template>
  <div class="presses-container">
    <div class="presses-title pc">
      <span>{{ title }}</span>
    </div>

    <div class="presses-title mobile">
      <!-- <span>{{ mobileTitle }}</span> -->
      <span>{{ title }}</span>
    </div>

    <div class="presses-logos">
      <div v-for="(img, idx) in getImages" :key="idx">
        <img
          :data-src="img.imgUrl"
          :alt="img.name"
          class="press-logo-img lazyload"
          width="131px"
          height="131px"
        >
      </div>
    </div>
    <div class="bottom">
      <svgicon name="subtract" class="bottom-icon" width="68" height="24" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PressSection',
  props: {
    title: {
      type: String,
      require: true
    },
    presses: {
      type: Array,
      require: true
    }
  },
  data () {
    return {
      mobileTitle: 'Recommended by'
    }
  },
  computed: {
    getImages () {
      const imageUrls = []
      this.presses.forEach((press) => {
        const imgUrl = require('@/assets/img/partners/' + press.imgName)
        const link = press.link
        imageUrls.push({ imgUrl, link, name: press.name })
      })
      return imageUrls
    }
  }
}
</script>

<style lang="scss" scoped>
$main-color: $woi-red-main;
.presses-container {
  width: 100%;
  border-top: 2px solid $main-color;
  border-bottom: 2px solid $main-color;
  display: flex;
  flex-direction: column;
  @include max-width($mobile) {
    align-items: center;
    border-bottom: none;
  }
  .presses-title {
    @include woi-header-4;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 16px;
    @include max-width($tablet-portrait) {
      @include woi-header-5;
    }
    @include max-width($mobile) {
      width: 334px;
      text-align: center;
      margin-top: -16px;
    }
    > span {
      background: #ffffff;
      padding: 0 23px;
      color: $woi-red-main;
    }
  }
  .presses-logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 141px;
    margin-bottom: 20px;
    @include max-width($tablet) {
      justify-content: space-around;
    }
    @include max-width($mobile) {
      width: 334px;
      height: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
    }
    @include max-width($mobile-narrow) {
      width: $narrow-width;
    }
    > div {
      max-width: 15%;
      @include max-width($tablet) {
        max-width: 15%;
      }

      @include max-width($mobile-extra) {
        margin-bottom: 20px;
        max-width: 88px;
      }
      @include max-width($mobile) {
        max-width: 90%;
      }
      @include max-width($mobile-narrow) {
        max-width: 121px;
      }
      .press-logo-img {
        height: auto;
        width: 100%;
      }
    }
    
  }
  .bottom {
    display: flex;
    justify-content: center;
    .bottom-icon {
      color: $main-color;
      background: #ffffff;
    }
    margin-bottom: -12px;
    @include max-width($mobile) {
      display: none;
    }
  }

  .pc {
    display: block;

    @include max-width($tablet-extra) {
      display: none;
    }
  }

  .mobile {
    display: none;

    @include max-width($tablet-extra) {
      display: block;
    }
  }
}
</style>
