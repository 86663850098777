export default {
  methods: {
    setRobotsTag () {
      if (process.env.SET_ENVIRONMENT === 'development') {
        return 'noindex'
      } else {
        return 'index'
      }
    }
  }
}