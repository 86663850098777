<template>
  <div :id="id" class="home-slider-container">
    <div v-if="list.length > 0" class="home-slider-wrapper" :style="{width: `${this.wrapperWidth}px`}">
      <VueSlickCarousel :ref="id" v-bind="slideSettings"  @beforeChange="onBeforeChange">
        <div v-for="(content, idx) in list" :key="idx">
          <slot name="content" :item="content" />
        </div>
      </VueSlickCarousel>
    </div>
    <div class="center-item">
      <slot name="button" />
    </div>
    <Pagination
      v-if="!isSingleSilder"
      :callback="choose"
      :number="list.length"
      :dots-number="list.length"
      :cur-index="dotIndex"
      :screen-width="screenWidth"
      :click-prev="goPrev"
      :click-next="goNext"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Pagination from '@/components/utils/Pagination'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import styles from '@/assets/sass/_variables.scss'

const mobileLarge = Number.parseInt(styles.mobileLarge) // 480
const cardWidthDesktop = 334
const cardWidthMobile = 264
const threeCardsLimit = 1060
const twoCardsLimit = Math.max(mobileLarge, cardWidthMobile * 2 + 60)

export default {
  name: 'HomeSlider',
  components: {
    Pagination,
    VueSlickCarousel
  },
  props: {
    id: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    cardWidth: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      curIndex: 0,
      sliderWidth: 0,
      defaultSettings: {
        infinite: true,
        dots: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false
      }
    }
  },
  computed: {
    ...mapGetters([
      'screenWidth'
    ]),
    isSingleSilder () {
      const slidesToShow = this.slideSettings.slidesToShow
      return this.list.length <= slidesToShow
    },
    dotIndex () {
      if (this.curIndex < 0) {
        return this.list.length + this.curIndex + 1
      }
      const res = this.curIndex % (this.list.length + 1)
      // console.log('res => ', res)
      if (res === 0) {
        return 1
      }
      return res + 1
    },
    customSettings () {
      if (this.screenWidth > threeCardsLimit) {
        return { slidesToShow: 3 }
      } else if (this.screenWidth > twoCardsLimit) {
        const cardWidth = this.screenWidth > 768 ? cardWidthDesktop : cardWidthMobile
        const rightPadding = Math.ceil((this.sliderWidth - cardWidth * 2) / 3)
        // console.log('location [2] rightPadding => ', rightPadding)
        // console.log(`twoCardsLimit:${twoCardsLimit},cardWidth:${cardWidth} rightPadding:${rightPadding}`)
        return {
          centerPadding: `${rightPadding}px 0 0`,
          slidesToShow: 2,
          centerMode: true
        }
      } else {
        const rightPadding = Math.ceil((this.sliderWidth - cardWidthMobile) / 3)
        // console.log('[1] sliderWidth => ', sliderWidth)
        // console.log('[1] rightPadding => ', rightPadding)
        return {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: `${rightPadding}px 0 0`,
        }
      }
    },
    slideSettings () {
      if (!process.browser) {
        return {
          ...this.defaultSettings,
          slidesToShow: 1,
          centerMode: false
        }
      }
      return { ...this.defaultSettings, ...this.customSettings }
    },
    wrapperWidth () {
      this.sliderWidth = this.getSliderWidth()
      // console.log('sliderWidth => ', sliderWidth)
      let cardNo = 1
      if (this.screenWidth > threeCardsLimit) {
        cardNo = 3
      } else if (this.screenWidth > twoCardsLimit) {
        // show 2 cards
        cardNo = 2
      }
      if (cardNo === 3) {
        // (w/3 - 334) * 2 + 334 * 3 = width
        const totalWidth = ((this.sliderWidth - this.cardWidth * cardNo) / (cardNo - 1) + this.cardWidth) * cardNo
        return totalWidth
      }
      return this.sliderWidth
    }
  },
  mounted () {
    if (process.browser) {
      this.sliderWidth = this.getSliderWidth()
    }
  },
  methods: {
    getSliderWidth () {
      if (!process.browser) {
        return 0
      }
      const curDiv = document.getElementById(this.id)
      return curDiv?.clientWidth ?? 0
    },
    goPrev () {
      this.$refs[this.id].prev()
    },
    goNext () {
      this.$refs[this.id].next()
    },
    onBeforeChange (oldIdx, newIndex) {
      this.curIndex = newIndex
    },
    choose (index) {
      this.$refs[this.id].goTo(index - 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.home-slider-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .home-slider-wrapper {
    position: relative;
    padding: 1rem 0;
    max-height: 500px;
    overflow: hidden;
  }
  .center-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@include max-width($tablet-portrait) {
  .home-slider-container {
    .center-item {
      order: 3;
      width: 100%;
    }
  }
}

</style>
