<template>
  <div class="pagination-wrapper">
    <svg
      v-if="showArrow"
      class="arrow prev-arrow"
      viewBox="0 0 24 24"
      :class="{ disabled: disablePrev }"
      :height="arrowSize"
      :width="arrowSize"
      xmlns="http://www.w3.org/2000/svg"
      @click="clickPrev"
    >
      <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
      <path d="M0-.5h24v24H0z" fill="none" />
    </svg>
    <div v-if="screenWidth > 1024" class="dots-wrapper">
      <div
        v-for="index in renderNumber"
        :key="index"
        class="dot"
        :class="{ selected: index === curIndex }"
        @click="callback(index)"
      />
    </div>
    <div v-else class="dots-wrapper">
      <div
        v-for="index in renderNumber"
        :key="index"
        class="dot"
        :class="{ selected: index === curIndex }"
      />
    </div>

    <svg
      v-if="showArrow"
      class="arrow next-arrow"
      viewBox="0 0 24 24"
      :class="{ disabled: disableNext }"
      :height="arrowSize"
      :width="arrowSize"
      xmlns="http://www.w3.org/2000/svg"
      @click="clickNext"
    >
      <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
      <path d="M0-.25h24v24H0z" fill="none" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    callback: Function,
    clickPrev: Function,
    clickNext: Function,
    number: {
      type: Number,
      require: true
    },
    curIndex: {
      type: Number,
      require: false,
      default: () => 1
    },
    screenWidth: {
      type: Number,
      required: true,
      default: () => 375
    },
    dotsNumber: {
      type: Number,
      required: false,
      default: () => null
    },
    arrowSize: {
      type: String,
      require: false,
      default: () => '16px'
    },
    showArrow: {
      type: Boolean,
      require: false,
      default: () => true
    },
    disablePrev: {
      type: Boolean,
      require: false,
      default: () => false
    },
    disableNext: {
      type: Boolean,
      require: false,
      default: () => false
    }
  },
  computed: {
    renderNumber () {
      if (this.dotsNumber) {
        return this.dotsNumber
      }
      if (this.screenWidth < 768) {
        return this.number
      }
      return Math.ceil(this.number / 3)
    }
  }
}
</script>
<style lang="scss" scoped>
$dot-size: 8px;
.pagination-wrapper {
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #443c47;
  @extend %description-2;

  .dots-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 6px;
    column-gap: 8px;
  }

  .dot {
    // margin: 0 4px;
    border-radius: 50%;
    width: $dot-size;
    height: $dot-size;
    background: #443c47;
    opacity: 0.25;
    &:hover {
      cursor: pointer;
    }
    &.selected {
      opacity: 1;
    }
    transition: opacity 1s;
  }

  .arrow {
    fill: #443c47;
    width: 32px;
    height: 32px;
    padding: 4px 8px;
    &.next-arrow {
      padding-right: 0;
      // margin-left: 8px;
    }
    &.prev-arrow {
      padding-left: 0;
      // margin-right: 8px;
    }

    &.disabled {
      opacity: 0.25;
      &:hover {
        cursor: not-allowed;
        fill: #443c47;
      }
    }
    &:hover {
      cursor: pointer;
      fill: $woi-red-main;
    }
  }
}
</style>
