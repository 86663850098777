
export const calAverage = (rating) => {
  // console.log('ratings: ', rating)
  if (rating >= 4.7) {
    return 5
  }
  const floor = Math.floor(rating)
  const remaining = rating - floor
  if (remaining >= 0.7) {
    return floor + 1
  } else if (remaining >= 0.2) {
    return floor + 0.5
  } else {
    return floor
  }
}
