<template>
  <div class="homepage-about-us top-border">
    <div class="on-border top-text">About Us</div>
    <img 
      :data-src="this.getContentfulImgURL({ url: this.aboutUs.imgUrl, width:600, height: 600 })" 
      class="lazyload about-us-image"
    >
    <div v-if="this.aboutUs.text" class="read-more-text">
      <div v-html="this.aboutUs.text"></div>
      <span>
        <a class="read-more-btn" href="https://www.walksofitaly.com/about/" target="_blank">Read More</a>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import imageOptimo from '~/mixins/imageOptimoMixin.js'

export default {
  name: 'AboutUs',
  mixins: [imageOptimo],
  computed: {
    ...mapGetters([
      'screenWidth',
      'aboutUs'
    ])
  }
}
</script>

<style lang="scss" scoped>
$imgSizeMax: 364px;
$imgSize: 40%;
.homepage-about-us {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 48px;
  &.top-border {
    position: relative;
    padding: 30px 0;
    border-top: 2px solid $woi-red-main;
    display: flex;
    justify-content: space-between;
    .on-border {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
      background-color: $white;
      &.top-text {
        width: fit-content;
        padding-left: 1rem;
        padding-right: 1rem;
        text-align: center;
        @include woi-header-4;
        color: $woi-red-main;
        top: -21px;
      }
    }
  }
  .about-us-image {
    max-width: $imgSizeMax;
    max-height: $imgSizeMax;
    width: $imgSize;
    height: $imgSize;
    border-radius: 50%;
    object-fit: cover;
  }
  .read-more-text {
    p {
      font-family: "Open Sans";
      font-weight: 400 !important;
      font-size: 18px;
      line-height: 26px;
      color: #6C757D;
      padding-bottom: 1rem;
      letter-spacing: 0 !important;
    }
    span {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .read-more-btn {
      font-family: "Open Sans";
      font-weight: 600;
      font-size: 16px !important;
      line-height: 19px;
      // text-decoration: underline !important;
      text-transform: uppercase;
      color: $woi-gold-main;
      border: 1px solid $woi-gold-main;
      padding: 12px 16px;
    }
  }
}

@include max-width($tablet-portrait) {
  .homepage-about-us {
    &.top-border {
      // gap: 1rem;
      .on-border {
        &.top-text {
          // width: 160px;
          @include woi-header-5;
          top: -16px;
        }
      }
    }
    .read-more-text {
      p {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}

$imgSizeMobile: 259px;
@include max-width($mobile-l){
  .homepage-about-us {
    flex-direction: column;
    &.top-border {
      padding: 30px 0;
      align-items: center;
      gap: 1rem;
      .on-border {
        &.top-text {
          // width: 160px;
          @include woi-header-5;
          top: -16px;
        }
      }
    }
    .about-us-image {
      width: $imgSizeMobile;
      height: $imgSizeMobile;
    }
    .read-more-text {
      p {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 0;
        margin-right: 0;
      }

      .read-more-btn {
        margin-left: 0;
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>