<template>
  <div class="tour-card-vertical">
    <!-- image and flag -->
    <nuxt-link :to="'/' + formattedData.citySlug + '/' + formattedData.slug + '/'" class="image-link" @click.native="productClick">
      <div class="thumbnail">
        <img :data-src="this.getContentfulImgURL({ url: formattedData.image, width:600, height: 400 })" class="lazyload tour-image">
        <client-only>
          <div v-if="showPromoTag" class="promo-tag" :style="getPromoStyle">
            {{ getPromoLabel() }}
          </div>
        </client-only>
      </div>
    </nuxt-link>
    <div class="tour-card-content-wrapper">
      <!-- tour title, review and description -->
      <div class="detail-wrapper">
        <div>
          <nuxt-link :to="'/' + formattedData.citySlug + '/' + formattedData.slug + '/'" @click.native="productClick">
            <h3 class="red tour-title" :title="formatedName">
              {{ formatedName }}
            </h3>
          </nuxt-link>
          <ReviewLine class="desktop-review review-line"
            :show-hours="false"
            :show-capacity="false"
            :review="reviewData"
          />
        </div>
      </div>
      <!-- price and view tour button -->
      <div class="button-price">
        <div v-if="getPrice(formattedData) !== '$0'" class="card-price flex">
          <span class="price-prefix">from</span>
          <span class="dollar-prefix" v-if="getPrefixSymbol()">
            {{ getPrefixSymbol() }}
          </span>
          <span>{{ getPrice(formattedData) }}</span>
        </div>
        <nuxt-link class="view-tour-btn" :to="'/' + formattedData.citySlug + '/' + formattedData.slug  + '/'" @click.native="productClick">
          <div class="book-tour-anchor btn-anim">VIEW TOUR</div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ReviewLine from '@/components/utils/ReviewLine'
import promoMixin from  '@/mixins/promoMixin.js'
import imageOptimo from '~/mixins/imageOptimoMixin.js'
export default {
  name: 'TourCardVertical',
  mixins: [ promoMixin, imageOptimo ],
  components: {
    ReviewLine
  },
  computed: {
    ...mapGetters([
      'screenWidth'
    ]),
    formattedData () {
      return {
        ...this.data,
        priceMap: this.data.priceMap ?? {
          currencies: this.data.currencies,
          anchorPrice: this.data.anchorPrice,
        }
      }
    },
    // remove unicode trademark
    formatedName () {
      return this.formattedData.title || this.formattedData.shortTitle
    },
    reviewData () {
      return {
        stars: this.formattedData.reviewStatus?.feedbackAverage || 0,
        reviewNumber: this.formattedData.reviewStatus?.feedbackCount || 0,
        hours: 0,
        capacity: 0
      }
    },
    showPromoTag () {
      if (this.formattedData?.promo?.show && this.formattedData?.promo?.promoLocation?.includes('Homepage')) {
        return true
      } else {
        return false
      }
    },
    getPromoStyle () {
      if (this.showPromoTag) {
        return `background-color: ${this.formattedData.promo?.promoTag?.bgColor || 'blue'};
          color: ${this.formattedData.promo?.promoTag?.textColor || 'white'};
        `
      } else {
        return ''
      }
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    productClick () {
      window.dataLayer.push({
        'event': 'productClick',
        'ecommerce': {
          'click': {
            'actionField': {'list': this.list},
            'products': [{
              'name': this.data.titleShort,
              'id': this.data.bookingTypeId,
              'price': this.getAnchorPriceNum(this.data),
              'category': this.data.citySlug.replace('-tours', '').charAt(0).toUpperCase() + this.data.citySlug.replace('-tours', '').slice(1),
              'position': this.position
            }]
          }
        }
      })
    },
    getPromoLabel () {
      if (this.showPromoTag) {
        return this.data.promo?.promoTag?.salesText
      } else {
        return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$padding: 30px;
$tour-pic-h: 214px;
.tour-card-vertical {
  display: flex;
  flex-direction: column;
  border: 1px solid $woi-grey-light;
  width: 334px;
  .thumbnail {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: $tour-pic-h;
    background-color: rgba(211, 211, 211, 0.5);
    .tour-image {
      width: 100%;
      height: $tour-pic-h;
      align-self: flex-start;
      object-fit: cover;
      transition: transform 940ms $cb-switch;
      &:hover {
        transform: scale(1.07) rotate(-.33deg);
      }
    }
    .flag {
      position: absolute;
      bottom: 0;
      height: $flag-height;
      background-color: $golden-starlight;
      // text-align: center;
      color: $white;
      width: 100%;
      // @extend %title-2;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-family: $font-sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
    }
    .promo-tag {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 12px;
      text-align: center;
      background-color: #1BB2EB;
      color: #F7F7F7;
      font-weight: 900;
      font-size: 18px;
    }
  }
  .tour-card-content-wrapper {
    padding: 8px;
    height: 242px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  h3.red {
    text-transform: none!important;
    @include woi-header-5;
    color: $woi-red-main;
    &:hover {
        text-decoration: underline;
    }
    &.tour-title {
      max-height: 88px;
      margin-bottom: 16px !important;
      line-height: 30px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
  .button-price {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    color: $woi-red-main;
    .card-price.flex {
      @extend %header-3;
    }
    .view-tour-btn {
      width: 100%;
      .book-tour-anchor {
        float: unset;
      }
    }
  }
  .card-price.flex {
    display: flex;
    align-items: center;
  }
  .price-prefix{
    font-family: Vollkorn;
    color:$woi-red-main;
    margin-right: 5px;
    @extend %title-1-bold;
  }
  .dollar-prefix {
    font-size: 26px;
  }
}

@include max-width($tablet-portrait){
  .tour-card-vertical {
    width: 264px;
    .tour-card-content-wrapper {
      padding: 8px;
      height: 216px;
    }
    h3.red {
      font-size: 18px !important;
      font-weight: 700 !important;
      line-height: 22px;
      &.tour-title {
        max-height: 68px;
        -webkit-line-clamp: 2;
      }
    }
    .button-price {
      .card-price.flex {
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 24px;
      }
    }
    .price-prefix{
      font-size: 18px !important;
      font-weight: 700 !important;
      line-height: 22px;
    }
    .dollar-prefix {
      font-size: 18px !important;
      font-weight: 700 !important;
      line-height: 22px;
    }
  }
}

</style>
