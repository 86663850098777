<template>
  <div class="home-blog-card">
    <a v-if="screenWidth > 1024" :href="info.pageLink" target="_blank" class="blog-card tour-link" draggable="false">
      <img
        class="home-blog-img lazyload"
        draggable="false"
        :data-src="info.imgUrl"
        loading="lazy"
        format="webp"
        :alt="info.description"
      />
      <div class="blog-date">
        {{ momentDate(info.date) }}
      </div>
      <div class="blog-description">
        {{ info.description }}
      </div>
    </a>
    <a v-else :href="info.pageLink" target="_blank" class="blog-card tour-link mobile-link-card" draggable="false" @click="clickLink(info.pageLink)">
      <img
        class="home-blog-img lazyload"
        draggable="false"
        :data-src="info.imgUrl"
        loading="lazy"
        format="webp"
        :alt="info.description"
      />
      <div class="blog-date">
        {{ momentDate(info.date) }}
      </div>
      <div class="blog-description">
        {{ info.description }}
      </div>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import imageOptimo from '~/mixins/imageOptimoMixin.js'
import moment from 'moment'

export default {
  name: 'HomeBlogCard',
  mixins: [imageOptimo],
  props: ['info'],
  computed: {
    ...mapGetters([
      'screenWidth'
    ]),
  },
  methods: {
    momentDate (value) {
      return moment(value).format('MMM DD, Y')
    },
    clickLink (link) {
      // use js to open link to fix click twice bug
      window.open(link)
    }
  }
}
</script>
<style lang="scss" scoped>
$imgH: 214px;
.home-blog-card .blog-card {
  width: 100%;
  height: 100%;
  position: relative;
  color: $white;
  overflow: hidden;

  &.tour-link {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // justify-content: center;
    .home-blog-img {
      background: #212529;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: $imgH;
      transition: transform 940ms $cb-switch;
      &:hover {
        transform: scale(1.07) rotate(-.33deg);
      }
    }
    .blog-date {
      margin-top: 1rem;
      color: #6C757D;
      @include paragraph-1;
      font-family: "Open Sans";
    }
    .blog-description {
      margin-top: 1rem;
      width: 100%;
      height: 100%;
      @include title-1;
      color: $blackberry;
      font-weight: 500;
    }
  }
}

@include max-width($mobile-l) {
  .home-blog-card .blog-card {
    .home-blog-img {
      height: 300px;
    }
    .blog-description {
      margin-top: 8px;
      font-size: 18px !important;
      line-height: 24px;
    }
  }
}
</style>
